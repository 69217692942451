export const PAGE_SIZE_VALUES = [
  {
    label: 10,
    value: 10,
  },
  {
    label: 100,
    value: 100,
  },
  {
    label: 300,
    value: 300,
  },
];

export const servicesSettingsTitles = {
  ORDERS: "orders",
  REVISIONS: "revizia",
  CHECK_PRICE: "checkPrice",
  VERIFICATION: "verification",
  SALES: "seller",
  DIPLACEMENTS: "diplacements",
  BARCODING: "barcoding",
  GLOBAL: "global",
  RETURNS: "returns",
  INVOICES: "invoice",
};

export const SETTINGS_KEYS = {
  USE_CHARACTERISTICS: "useCharacteristics",
  USE_ENTEREXPIRATION_DATES: "useEnteringExpirationDates",
  USE_VIRTUAL_KEYBOARD: "showVirtualKeyboard",
  SHOW_SUM_COLUMN: "showSumColumn",
  SHOW_ART_COLUMN: "showArtColumn",
  SHOW_PRICE_COLUMN: "showPriceColumn",
};
