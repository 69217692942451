import { notification } from "antd";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getServiceSettingsObject } from "../../api/settings/settings";
import ModalBarcodeCameraReader from "../../components/camera-barcode-reader/ModalBarcodeCameraReader";
import {
  DOCUMENTS_TITLES,
  DOCUMENT_OPEN_ACTIONS,
} from "../../components/documents/_CONSTANTS/constants";
import { servicesSettingsTitles } from "../../components/documents/_CONSTANTS/settings";
import Footer from "../../components/documents/invoices/create/Footer";
import Main from "../../components/documents/invoices/create/Main";
import ModalEnterProduct from "../../components/documents/manage-documents/modal-enter-product/ModalEnterProducts";
import {
  getColumnsDataGlobal,
  getDefaultInvoiceColumns,
} from "../../components/documents/manage-documents/table-components/documents-columns";
import BottomPageTitle from "../../components/header/BottomPageTitle";
import ModalChooseProducts from "../../components/products/products-tree/ModalChooseProducts";
import { getDiffColumnVisibility } from "../../components/table-utils/last-entered-products/Columns";
import { TABLES } from "../../db/constants/tables";
import { db } from "../../db/dexie-db/db";
import { getDocumentProductsWithoutDeleted } from "../../db/documents-funtions";
import {
  getCurrentDocProducts,
  getProductsWithIndexes,
  getSortedProductsByTimeAdded,
  setNewProductToTable,
} from "../../db/products-functions";
import { useAuth } from "../../hook/useAuth";
import { listenToBackgroundEnteredBarcode } from "../../utils/eventListeners";

const DOCUMENT_TITLE = DOCUMENTS_TITLES.INVOICE;
const DOCUMENT_ACTION = DOCUMENT_OPEN_ACTIONS.CREATE;
const CURRENT_DOCUMENT_TABLE = TABLES.CURRENT_INVOICE;

let backgroundEnteredBarcode = "";

export default function CreateInvoice() {
  const { user } = useAuth();

  const invoiceSettings = getServiceSettingsObject(
    user.settings,
    servicesSettingsTitles.INVOICES
  );
  const defaultInvoiceColumns = getDefaultInvoiceColumns(invoiceSettings);

  const columns = useMemo(() => {
    const res = getColumnsDataGlobal(defaultInvoiceColumns, invoiceSettings);
    return res;
  }, [invoiceSettings, defaultInvoiceColumns]);

  const location = useLocation();
  const navigate = useNavigate();
  const docOrder = location?.state?.document;

  const [isModalProductOpen, setIsModalProductOpen] = useState(false);
  const [isModalChooseProductsOpen, setIsModalChooseProductsOpen] =
    useState(false);
  const [isModalCameraBarcodeReaderOpen, setIsModalCameraBarcodeReaderOpen] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [settings, setSettings] = useState(invoiceSettings);

  const [productsFromOrder] = useState(
    docOrder ? JSON.parse(JSON.stringify(docOrder.products)) : ""
  );

  const [backgroundEnteredBarcodeValue, setBackgroundEnteredBarcodeValue] =
    useState("");

  useEffect(() => {
    document.addEventListener("keydown", detectKeyDownEvent);
    return () => {
      document.removeEventListener("keydown", detectKeyDownEvent);
    };
  }, []);

  const detectKeyDownEvent = (e) => {
    backgroundEnteredBarcode = listenToBackgroundEnteredBarcode(
      e,
      setBackgroundEnteredBarcodeValue,
      backgroundEnteredBarcode
    );
  };
  const setDataFromSource = useCallback(async () => {
    const badProducts = productsFromOrder.filter(
      (prod) => prod.guid === undefined
    );
    if (badProducts.length > 0) {
      notification.error({
        message: "Помилка",
        description:
          "У документі є невідомі товари, створити прихід неможливо!",
        duration: 5,
      });
      return navigate(-1, { replace: true });
    }
    const timeAdd = +dayjs().valueOf().toString();

    const productsFromSourceWithoutQty = productsFromOrder.map((prod, ind) => ({
      ...prod,
      qty: 0,
      qtyFromSource: prod.qty,
      timeAdded: timeAdd + ind,
      key: timeAdd + ind,
    }));

    const sortedProductsWithIndexes = getProductsWithIndexes(
      productsFromSourceWithoutQty
    );
    setProducts(sortedProductsWithIndexes);
    await db.CurrentInvoice.bulkPut(sortedProductsWithIndexes);
  }, [productsFromOrder, navigate]);

  const getData = useCallback(async () => {
    const products = await getCurrentDocProducts(CURRENT_DOCUMENT_TABLE);

    if (docOrder && !products.length) {
      setDataFromSource();
      return setLoading(false);
    }

    if (products.length > 0) {
      const sortedProducts = getSortedProductsByTimeAdded(products);
      const sortedProductsWithIndexes = getProductsWithIndexes(sortedProducts);
      setProducts(sortedProductsWithIndexes);
      return setLoading(false);
    }

    setLoading(false);
  }, [docOrder, setDataFromSource, setProducts, setLoading]);

  useEffect(() => {
    getData();
  }, [getData]);

  const removeProductFromDocument = async (product) => {
    const arrayWithoutDeletedProduct = await getDocumentProductsWithoutDeleted(
      products,
      product,
      CURRENT_DOCUMENT_TABLE,
      DOCUMENT_ACTION
    );
    setProducts(arrayWithoutDeletedProduct);
  };

  const afterChangeProductQty = () => {
    getData();
  };

  return (
    <>
      <Main
        products={products}
        setProducts={setProducts}
        loading={loading}
        document={docOrder}
        action={
          docOrder ? DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE : DOCUMENT_ACTION
        }
        settings={settings}
        afterChangeProductQty={afterChangeProductQty}
        removeProductFromDocument={removeProductFromDocument}
        columns={columns}
        dbTable={CURRENT_DOCUMENT_TABLE}
      ></Main>
      {(isModalProductOpen || backgroundEnteredBarcodeValue) && (
        <ModalEnterProduct
          backgroundEnteredBarcode={backgroundEnteredBarcodeValue}
          setBackgroundEnteredBarcodeValue={setBackgroundEnteredBarcodeValue}
          action={
            docOrder
              ? DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE
              : DOCUMENT_ACTION
          }
          dbTable={CURRENT_DOCUMENT_TABLE}
          products={products}
          setProducts={setProducts}
          isModalProductOpen={isModalProductOpen}
          setIsModalProductOpen={setIsModalProductOpen}
          settings={settings}
          weightTemplate={user.weightTemplate}
          serviceTitle={DOCUMENT_TITLE}
          showDiffColumn={getDiffColumnVisibility(
            DOCUMENT_TITLE,
            docOrder ? true : false
          )}
          focusInputQty={true}
          columns={columns}
          afterChangeProductQty={afterChangeProductQty}
          removeProductFromDocument={removeProductFromDocument}
        ></ModalEnterProduct>
      )}
      {isModalCameraBarcodeReaderOpen && (
        <ModalBarcodeCameraReader
          action={
            docOrder
              ? DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE
              : DOCUMENT_ACTION
          }
          products={products}
          setProducts={setProducts}
          setNewProductToTable={setNewProductToTable}
          isModalCameraBarcodeReaderOpen={isModalCameraBarcodeReaderOpen}
          focusInputQty={true}
          setIsModalCameraBarcodeReaderOpen={setIsModalCameraBarcodeReaderOpen}
          dbTable={CURRENT_DOCUMENT_TABLE}
          serviceTitle={DOCUMENT_TITLE}
          settings={settings}
          weightTemplate={user.weightTemplate}
          updateProducts={getData}
        ></ModalBarcodeCameraReader>
      )}
      {isModalChooseProductsOpen && (
        <ModalChooseProducts
          products={products}
          setProducts={setProducts}
          setNewProductToTable={setNewProductToTable}
          isModalChooseProductsOpen={isModalChooseProductsOpen}
          setIsModalChooseProductsOpen={setIsModalChooseProductsOpen}
          dbTable={CURRENT_DOCUMENT_TABLE}
          serviceTitle={DOCUMENT_TITLE}
          action={
            docOrder
              ? DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE
              : DOCUMENT_ACTION
          }
          settings={settings}
        ></ModalChooseProducts>
      )}
      <Footer
        setIsModalProductOpen={setIsModalProductOpen}
        setIsModalCameraBarcodeReaderOpen={setIsModalCameraBarcodeReaderOpen}
        setIsModalChooseProductsOpen={setIsModalChooseProductsOpen}
        settings={settings}
        setSettings={setSettings}
      ></Footer>
      <BottomPageTitle title={DOCUMENT_TITLE}></BottomPageTitle>
    </>
  );
}
