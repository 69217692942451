import { TableOutlined } from "@ant-design/icons";
import { Radio, Space, Switch } from "antd";
import {
  getUpdatedSettings,
  updateUserSettInSql,
} from "../../../../api/settings/settings";
import { useAuth } from "../../../../hook/useAuth";
import Menu from "../../../menu/Menu";
import {
  PAGE_SIZE_VALUES,
  servicesSettingsTitles,
  SETTINGS_KEYS,
} from "../../_CONSTANTS/settings";

function MenuComponent(props) {
  const { user, updateCurrentUser } = useAuth();

  const onChangeColorDifferents = (value) => {
    props.setSettings({ ...props.settings, colorDifferents: value });
    updateUserSettInSql(user, "colorDifferents", value, "invoice");
    updateUserWithNewSettings(
      user.settings,
      "colorDifferents",
      value,
      "invoice"
    );
  };

  const onChangeShowBarcodes = (value) => {
    props.setSettings({ ...props.settings, showBarcodes: value });
    updateUserSettInSql(user, "showBarcodes", value, "invoice");
    updateUserWithNewSettings(user.settings, "showBarcodes", value, "invoice");
  };

  const onChangePageSize = ({ target: { value } }) => {
    props.setSettings({ ...props.settings, pageSize: value });
    updateUserSettInSql(user, "pageSize", value, "invoice");
    updateUserWithNewSettings(user.settings, "pageSize", value, "invoice");
  };

  const updateUserWithNewSettings = (
    allSettings,
    settName,
    settValue,
    serviceName
  ) => {
    const updatedSettings = getUpdatedSettings(
      allSettings,
      settName,
      settValue,
      serviceName
    );
    const updatedLoggedInUser = { ...user, settings: updatedSettings };
    updateCurrentUser(updatedLoggedInUser);
  };

  const onChangeAskPrice = (value) => {
    props.setSettings({ ...props.settings, askPrice: value });
    updateUserSettInSql(user, "askPrice", value, "invoice");
    updateUserWithNewSettings(user.settings, "askPrice", value, "invoice");
  };

  const onChangeShowPriceColumn = (value) => {
    props.setSettings({ ...props.settings, showPriceColumn: value });
    updateUserSettInSql(
      user,
      SETTINGS_KEYS.SHOW_PRICE_COLUMN,
      value,
      servicesSettingsTitles.INVOICES
    );

    const updatedSettings = getUpdatedSettings(
      user.settings,
      SETTINGS_KEYS.SHOW_PRICE_COLUMN,
      value,
      servicesSettingsTitles.INVOICES
    );
    const updatedLoggedInUser = { ...user, settings: updatedSettings };

    updateCurrentUser(updatedLoggedInUser);
  };
  const onChangeShowArtInTable = (value) => {
    props.setSettings({ ...props.settings, showArtColumn: value });
    updateUserSettInSql(
      user,
      SETTINGS_KEYS.SHOW_ART_COLUMN,
      value,
      servicesSettingsTitles.INVOICES
    );
    const updatedSettings = getUpdatedSettings(
      user.settings,
      SETTINGS_KEYS.SHOW_ART_COLUMN,
      value,
      servicesSettingsTitles.INVOICES
    );
    const updatedLoggedInUser = { ...user, settings: updatedSettings };
    updateCurrentUser(updatedLoggedInUser);
  };

  const onChangeShowSumColumn = (value) => {
    props.setSettings({ ...props.settings, showSumColumn: value });
    updateUserSettInSql(
      user,
      SETTINGS_KEYS.SHOW_SUM_COLUMN,
      value,
      servicesSettingsTitles.INVOICES
    );
    const updatedSettings = getUpdatedSettings(
      user.settings,
      SETTINGS_KEYS.SHOW_SUM_COLUMN,
      value,
      servicesSettingsTitles.INVOICES
    );
    const updatedLoggedInUser = { ...user, settings: updatedSettings };
    updateCurrentUser(updatedLoggedInUser);
  };

  const settingsTab = (
    <>
      <Space direction="vertical">
        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.askPrice}
            size="default"
            onChange={onChangeAskPrice}
          />
          Запитувати ціну
        </Space>
        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.colorDifferents}
            size="default"
            onChange={onChangeColorDifferents}
          />
          Підсвітка розбіжностей
        </Space>
        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.showBarcodes}
            size="default"
            onChange={onChangeShowBarcodes}
          />
          Показувати штрихкоди
        </Space>

        <Space direction="horizontal">
          <Radio.Group
            options={PAGE_SIZE_VALUES}
            onChange={onChangePageSize}
            value={props?.settings?.pageSize}
            optionType="button"
            buttonStyle="solid"
          />
          Товарів на сторінку
        </Space>
      </Space>
    </>
  );
  const columnsTab = (
    <>
      <Space direction="vertical">
        <Space direction="horizontal">
          <Switch
            checked={
              props?.settings?.showArtColumn === undefined
                ? false
                : props?.settings?.showArtColumn
            }
            size="default"
            onChange={onChangeShowArtInTable}
          />
          Артикул
        </Space>
        <Space direction="horizontal">
          <Switch
            checked={
              props?.settings?.showSumColumn === undefined
                ? true
                : props?.settings?.showSumColumn
            }
            size="default"
            onChange={onChangeShowSumColumn}
          />
          Сума
        </Space>
        <Space direction="horizontal">
          <Switch
            checked={
              props?.settings?.showPriceColumn === undefined
                ? true
                : props?.settings?.showPriceColumn
            }
            size="default"
            onChange={onChangeShowPriceColumn}
          />
          Ціна
        </Space>
      </Space>
    </>
  );
  const menuItems = [
    {
      key: "columns-tab",
      label: (
        <span>
          <TableOutlined /> Колонки
        </span>
      ),
      children: columnsTab,
    },
  ];
  return (
    <>
      <Menu settingsTab={settingsTab} menuItems={menuItems}></Menu>
    </>
  );
}
export default MenuComponent;
