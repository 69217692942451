import {
  CalendarOutlined,
  CloudDownloadOutlined,
  CloudSyncOutlined,
  LockOutlined,
  LogoutOutlined,
  MenuOutlined,
  MobileOutlined,
  PlusCircleFilled,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Card, Dropdown, List, message, Space } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserSetting } from "../api/settings/settings";
import ImportModal from "../components/import/ImportModal";
import { TABLES } from "../db/constants/tables";
import { db } from "../db/dexie-db/db";
import { getAllRecords } from "../db/dexie-db/functions";
import { deleteOldServicesDocuments } from "../db/documents-funtions";
import { useAuth } from "../hook/useAuth";
import "../styles/home.css";
import { getUpdateDate } from "../utils/getLatestUpdateDate";
import { getStorageSpace } from "../utils/otherFunctions";

dayjs.extend(customParseFormat);

const LockService = <LockOutlined style={{ color: "red" }} />;

export default function Home() {
  const navigate = useNavigate();

  const { user, signOut } = useAuth();

  const [isOpenUpdateDataModal, setIsOpenUpdateDataModal] = useState(false);

  const checkStorage = useCallback(async () => {
    const storageSpace = await getStorageSpace(user.username);
    if (!storageSpace) return;
    if (storageSpace.usedMegaBytes > 250) {
      message.warning(
        `Додаток займає ${storageSpace.usedMegaBytes} Мб, необхідно очистити дані у налаштуваннях браузера!`
      );
    }
  }, [user.username]);

  const setDocsVerificationData = useCallback(async () => {
    const html = document.getElementById("docs-verification-qty");

    const documents = await getAllRecords(TABLES.VERIFICATION);
    html.textContent = documents.length
      ? "(" + String(documents.length) + ")"
      : "";
    if (documents.length > 50) {
      message.info("Очистіть розділ реалізацій від застарілих документів!");
    }
  }, []);

  const setBarcodingData = useCallback(async () => {
    const html = document.getElementById("barcoding-docs-qty");
    if (user.services.barcoding) {
      const docsQty = await db[TABLES.BARCODING].count();
      html.textContent = docsQty ? "(" + String(docsQty) + ")" : "";
    }
  }, [user.services.barcoding]);

  const setSalesData = useCallback(async () => {
    const html = document.getElementById("sales-docs-qty");
    if (user.services.sales) {
      const docsQty = await db[TABLES.SALES].count();
      html.textContent = docsQty ? "(" + String(docsQty) + ")" : "";
    }
  }, [user.services.sales]);

  const setRevisionData = useCallback(async () => {
    const html = document.getElementById("revisions-docs-qty");
    if (user.services.inventory) {
      const docsQty = await db[TABLES.REVISIONS].count();
      html.textContent = docsQty ? "(" + String(docsQty) + ")" : "";
    }
  }, [user.services.inventory]);

  const setInvoiceData = useCallback(async () => {
    const html = document.getElementById("invoices-docs-qty");
    if (user.services.invoice) {
      const docsQty = await db[TABLES.INVOICES].count();
      const qty = docsQty ? "(" + String(docsQty) + ")" : "";
      html.textContent = qty;
    }
  }, [user.services.invoice]);

  const setReturnsData = useCallback(async () => {
    const html = document.getElementById("returns-docs-qty");
    if (user.services.invoice) {
      const docsQty = await db[TABLES.RETURNS].count();
      const returnsDocsQty = docsQty ? "(" + String(docsQty) + ")" : "";
      html.textContent = returnsDocsQty;
    }
  }, [user.services.invoice]);
  const setOrdersData = useCallback(async () => {
    const html = document.getElementById("orders-docs-qty");
    if (user.services.orders) {
      const docsQty = await db[TABLES.ORDERS].count();
      html.textContent = docsQty ? "(" + String(docsQty) + ")" : "";
    }
  }, [user.services.orders]);
  const setDiplacementsData = useCallback(async () => {
    const html = document.getElementById("diplacements-docs-qty");
    if (user.services.diplacements) {
      const docsQty = await db[TABLES.DIPLACEMENTS].count();
      html.textContent = docsQty ? "(" + String(docsQty) + ")" : "";
    }
  }, [user.services.diplacements]);

  const setCheckPriceData = useCallback(async () => {
    const html = document.getElementById("check-price-docs-qty");
    if (user.services.checkPrice) {
      const checkPriceDocsQty = await db[TABLES.CHECK_PRICE].count();
      const docsQty = checkPriceDocsQty
        ? "(" + String(checkPriceDocsQty) + ")"
        : "";
      html.textContent = docsQty;
    }
  }, [user.services.checkPrice]);

  const getData = useCallback(async () => {
    try {
      await setCheckPriceData();
      await setReturnsData();
      await setInvoiceData();
      await setOrdersData();
      await setDiplacementsData();
      await setRevisionData();
      await setSalesData();
      await setDocsVerificationData();
      await setBarcodingData();
    } catch (error) {
      console.error("Error fetching data", error);
    }
  }, [
    setCheckPriceData,
    setReturnsData,
    setInvoiceData,
    setOrdersData,
    setDiplacementsData,
    setRevisionData,
    setSalesData,
    setDocsVerificationData,
    setBarcodingData,
  ]);

  const setUserInfo = useCallback(async () => {
    const updateDate = getUpdateDate();
    const html = document.getElementById("homeUpdatedText");
    if (html) {
      const text = updateDate
        ? ` Оновлено: ${updateDate}`
        : ` Оновлено: Невідомо`;
      html.textContent = text;
    }
  }, []);

  useEffect(() => {
    setUserInfo();
    getData();
  }, [isOpenUpdateDataModal, setUserInfo, getData]);
  const processDeleteOldDocuments = useCallback(async () => {
    const daysForStoringDocuments = getUserSetting(
      "daysForStoringDocuments",
      user.settings,
      "global"
    );
    const daysForStoring = daysForStoringDocuments || 7;

    await deleteOldServicesDocuments(daysForStoring, getData);
  }, [user.settings, getData]);

  useEffect(() => {
    processDeleteOldDocuments();
    checkStorage();
  }, [processDeleteOldDocuments, checkStorage]);

  const userExtraItems = [
    {
      key: "settings",
      onClick: () => navigate("settings"),
      label: (
        <span>
          <Space direction="horizontal">
            <SettingOutlined></SettingOutlined>
            Налаштування
          </Space>
        </span>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "logoout",
      onClick: () => signOut(() => navigate("/")),

      label: (
        <span style={{ width: "100%" }}>
          <Space direction="horizontal">
            <LogoutOutlined></LogoutOutlined>
            Вихід
          </Space>
        </span>
      ),
    },
  ];

  const userCard = {
    title: (
      <>
        <UserOutlined /> Користувач: {user.username}{" "}
      </>
    ),
    extra: (
      <>
        <Dropdown
          menu={{
            items: userExtraItems,
          }}
          placement="topRight"
          trigger={["click"]}
        >
          <Space>
            <MenuOutlined style={{ fontSize: "20px" }} />
          </Space>
        </Dropdown>
      </>
    ),
  };

  const userDevicesCard = {
    title: (
      <>
        <MobileOutlined /> Вільних пристроїв:{" "}
        {Number(user.num_devices - user.token_used)}
      </>
    ),
  };
  const userExpirationDateCard = {
    title: (
      <>
        <CalendarOutlined /> Доступ до:{" "}
        {dayjs(user.expiration_date).format("DD.MM.YYYY")}
      </>
    ),
  };

  const userUpdateDataCard = {
    title: (
      <>
        <CloudSyncOutlined />
        <span id="homeUpdatedText"></span>
      </>
    ),
    extra: (
      <span>
        <CloudDownloadOutlined style={{ fontSize: "20px" }} key="update" />
      </span>
    ),
  };

  const services = [
    {
      title: "Ревізія",
      id: "revisions-docs-qty",
      listLink: "revisions",
      createLink: "revisions/create",
      disabled: user.services.inventory ? false : true,
    },
    {
      title: "Прийом товарів",
      id: "invoices-docs-qty",
      listLink: "invoices",
      createLink: "invoices/create",
      disabled: user.services.invoice ? false : true,
    },
    {
      title: "Замовлення",
      id: "orders-docs-qty",
      listLink: "orders",
      createLink: "orders/create",
      disabled: user.services.orders ? false : true,
    },
    {
      title: "Повернення",
      id: "returns-docs-qty",
      listLink: "returns",
      createLink: "returns/create",
      disabled: user.services.invoice ? false : true,
    },
    {
      title: "Переміщення",
      id: "diplacements-docs-qty",
      listLink: "diplacements",
      createLink: "diplacements/create",
      disabled: user.services.diplacements ? false : true,
    },
    {
      title: "Контроль цін",
      id: "check-price-docs-qty",
      listLink: "check-price",
      createLink: "check-price/create",
      disabled: user.services.checkPrice ? false : true,
    },
    {
      title: "Реалізація",
      id: "sales-docs-qty",
      listLink: "sales",
      createLink: "sales/create",
      disabled: user.services.sales ? false : true,
    },
    {
      title: "Перевірка документів",
      id: "docs-verification-qty",
      listLink: "docs-verification",
      createLink: "docs-verification",
      disabled: false,
    },
    {
      title: "Штрих-кодування",
      id: "barcoding-docs-qty",
      listLink: "barcoding",
      createLink: "barcoding/create",
      disabled: user.services.barcoding ? false : true,
    },
  ];

  return (
    <>
      <Card
        style={{ margin: "12px 24px" }}
        title={userUpdateDataCard.title}
        styles={{
          body: { padding: "10px 10px" },
          header: { padding: "10px 10px" },
        }}
        extra={userUpdateDataCard.extra}
        onClick={() => {
          setIsOpenUpdateDataModal(true);
        }}
      >
        {userExpirationDateCard.title}
      </Card>
      <List
        style={{ margin: "12px 24px" }}
        itemLayout="horizontal"
        dataSource={services}
        renderItem={(item) => (
          <List.Item
            actions={[
              <PlusCircleFilled
                className="homePageServiceIcon"
                key="setting"
                onClick={
                  !item.disabled
                    ? () => navigate(item.createLink)
                    : () => {
                        message.error("Доступ заборонено!");
                      }
                }
              />,
            ]}
          >
            <List.Item.Meta
              className="services-list"
              onClick={
                !item.disabled
                  ? () => navigate(item.listLink)
                  : () => {
                      message.error("Доступ заборонено!");
                    }
              }
              title={item.title}
              description={item.description}
            />
            {item.disabled ? LockService : <h6 id={item.id}> </h6>}
          </List.Item>
        )}
      />
      <Card
        style={{ margin: "12px 24px" }}
        title={userCard.title}
        styles={{
          body: { padding: "10px 10px" },
          header: { padding: "10px 10px" },
        }}
        extra={userCard.extra}
      >
        {userDevicesCard.title}
      </Card>

      <ImportModal
        open={isOpenUpdateDataModal}
        setIsOpenUpdateDataModal={setIsOpenUpdateDataModal}
      ></ImportModal>
    </>
  );
}
