import { message } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getServiceSettingsObject } from "../../api/settings/settings";
import {
  DOCUMENTS_EDITING_TITLES,
  DOCUMENT_OPEN_ACTIONS,
} from "../../components/documents/_CONSTANTS/constants";
import { servicesSettingsTitles } from "../../components/documents/_CONSTANTS/settings";
import Main from "../../components/documents/invoices/create/Main";
import Footer from "../../components/documents/invoices/edit/Footer";
import {
  getColumnsDataGlobal,
  getDefaultInvoiceColumns,
} from "../../components/documents/manage-documents/table-components/documents-columns";
import BottomPageTitle from "../../components/header/BottomPageTitle";
import { TABLES } from "../../db/constants/tables";
import { getRecord } from "../../db/dexie-db/functions";
import {
  getDocumentProductsWithoutDeleted,
  getRecordsForTable,
} from "../../db/documents-funtions";
import { useAuth } from "../../hook/useAuth";

export default function EditInvoice() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const invoiceSettings = getServiceSettingsObject(
    user.settings,
    servicesSettingsTitles.INVOICES
  );
  const defaultInvoiceColumns = getDefaultInvoiceColumns(invoiceSettings);

  const columns = useMemo(() => {
    const res = getColumnsDataGlobal(defaultInvoiceColumns, invoiceSettings);
    return res;
  }, [invoiceSettings, defaultInvoiceColumns]);

  const params = useParams();
  const id = params.id;
  const [document, setDocument] = useState();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [settings, setSettings] = useState(invoiceSettings);

  const getData = useCallback(async () => {
    const doc = await getRecord(TABLES.INVOICES, Number(id));
    if (!doc) {
      message.error("Помилка отримання даних документа");
    }
    const products = doc.products;
    setDocument(doc);
    const badProducts = products.filter((prod) => !prod.title || !prod.guid);
    if (badProducts.length) {
      message.error(
        "Помилка отримання товарів з документу, перевірте правильність вигрузки!"
      );
      return navigate(`/invoices`);
    }
    setProducts(getRecordsForTable(products));
    setLoading(false);
  }, [id, navigate]);

  useEffect(() => {
    getData();
  }, [getData]);

  const removeProductFromDocument = async (product) => {
    const arrayWithoutDeletedProduct = await getDocumentProductsWithoutDeleted(
      products,
      product,
      null,
      DOCUMENT_OPEN_ACTIONS.EDIT
    );
    setProducts(arrayWithoutDeletedProduct);
  };

  const afterChangeProductQty = (newProduct) => {
    setProducts((prevProducts) => {
      const productToChange = prevProducts.find(
        (product) => product.guid === newProduct.guid
      );

      if (productToChange) productToChange.qty = newProduct.qty;
      return prevProducts;
    });
  };

  return (
    <>
      <Main
        products={products}
        setProducts={setProducts}
        loading={loading}
        action={DOCUMENT_OPEN_ACTIONS.EDIT}
        document={document}
        settings={settings}
        removeProductFromDocument={removeProductFromDocument}
        columns={columns}
        afterChangeProductQty={afterChangeProductQty}
      ></Main>
      <Footer settings={settings} setSettings={setSettings}></Footer>
      <BottomPageTitle
        title={DOCUMENTS_EDITING_TITLES.EDITING_INVOICE}
      ></BottomPageTitle>
    </>
  );
}
